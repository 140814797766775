<script setup lang="ts">
import capitalize from 'lodash-es/capitalize'
import debounce from 'lodash-es/debounce'
import omitBy from 'lodash-es/omitBy'
import { ref, computed, watch, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import FeedControls from '/~/extensions/health/components/feed/feed-controls.vue'
import FeedView from '/~/extensions/health/components/feed/feed-view.vue'
import FeedTile from '/~/extensions/health/components/feed/tile/feed-tile'
import FeedTabs from '/~/extensions/health/components/tabs.vue'
import { useExercises } from '/~/extensions/health/composables'
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'
import { useEditablePage } from '/~/composables/cms/use-editable-page'

const tabs = [
  {
    label: 'Mind',
    route: { path: 'mind-and-body', query: { type: 'mind' } },
  },
  {
    label: 'Move',
    route: { path: 'mind-and-body', query: { type: 'move' } },
  },
  {
    label: 'Mind Silver',
    route: {
      path: 'mind-and-body',
      query: { type: 'silver', subtype: 'mind' },
    },
  },
  {
    label: 'Move Silver',
    route: {
      path: 'mind-and-body',
      query: { type: 'silver', subtype: 'move' },
    },
  },
  {
    label: 'Saved Playlist',
    route: { path: 'mind-and-body', query: { type: 'saved' } },
  },
]

const route = useRoute()
const router = useRouter()
const { selectedFeedId, selectedFeed, selectFeed, saved } = useExercises()
const { page } = useEditablePage()

const processing = ref(true)
const filters = ref<InstanceType<typeof FeedControls> | null>(null)
const search = ref(route.query?.search ?? '')
const category = ref((route.query?.category as string | undefined) ?? null)

const type = computed(() => (route.query?.type as string) ?? 'mind')

const subtype = computed(() => route.query?.subtype as string | undefined)

const params = computed(() => {
  return { search: search.value, category: category.value }
})

const catalogType = computed(() => {
  const catalog = capitalize(type.value)
  const subCatalog = subtype.value ? `${capitalize(subtype.value)} - ` : ''

  return `'EonX Category': '${subCatalog}${catalog}'`
})

async function useFeed() {
  selectFeed(type.value)

  if (selectedFeed.value) {
    selectedFeed.value.reset()
    selectedFeed.value.setFilter({
      filters:
        type.value === 'saved'
          ? saved.value.map((id) => 'objectID:' + id).join(' OR ') ||
            'objectID:null'
          : catalogType.value,
    })

    if (selectedFeed.value) {
      selectedFeed.value.getData()
    }
  } else {
    console.warn('feeds non-consistent')
  }

  processing.value = false
}

const onSearch = debounce(function (query) {
  search.value = query
  selectedFeed.value.setFilter({ query })
  useFeed()
  setRoute()
}, 350)

function changeCategory(value: string) {
  category.value = value
  selectedFeed.value.setParams({
    facetFilters:
      value && value !== 'All Categories'
        ? [`Collections:${value}`]
        : undefined,
  })
  useFeed()
  setRoute()
}

function setRoute() {
  const preQuery = {
    search: search.value,
    category: category.value,
    type: type.value,
    subtype: subtype.value,
  }

  const query = omitBy(preQuery, (v) => !v)

  router.replace({ query }).catch()
}

function getData() {
  processing.value = true
  filters.value?.resetCategory()
  useFeed()
  selectedFeed.value.getCategories()

  const body = document.getElementById('health-content')

  if (body) {
    body.scrollTop = 0
  }
}

watch(
  () => type,
  (newTypeValue) => {
    setRoute()
    if (newTypeValue.value !== selectedFeedId.value) {
      getData()
    }

    if (newTypeValue.value === 'silver' && !subtype.value) {
      router.replace({ params: { subtype: 'move' } }).catch()
    }
  },
  { deep: true }
)

watch(
  () => subtype,
  (newSubTypeValue) => {
    setRoute()
    if (type.value === 'silver' && !newSubTypeValue.value) {
      router.replace({ params: { subtype: 'move' } }).catch()
    }

    getData()
  },
  { deep: true }
)

onMounted(() => {
  useFeed()
  selectedFeed.value.getCategories()
})
</script>

<template>
  <div class="mx-auto max-w-7xl">
    <feed-tabs :tabs="tabs" :type="type" :subtype="subtype" class="sm:hidden" />

    <widgets-builder v-if="page && page.widgets" :widgets="page.widgets" />

    <feed-view>
      <template #tabs>
        <feed-tabs
          :tabs="tabs"
          :type="type"
          :subtype="subtype"
          class="hidden sm:block"
        />
      </template>

      <template #controls>
        <div class="w-full">
          <feed-controls
            v-if="selectedFeed"
            ref="filters"
            placeholder="Search for a video..."
            :categories="selectedFeed.categories"
            :params="params"
            without-category
            @input="onSearch"
            @category-update="changeCategory"
          />
        </div>
      </template>

      <ew-catalog-processor
        v-if="selectedFeed"
        class="sm:min-h-96"
        :processor="selectedFeed"
        :columns="{
          xs: 2,
          sm: 3,
          lg: 4,
        }"
      >
        <template #tile="{ tile }">
          <feed-tile :tile="tile" />
        </template>

        <template #empty>
          <div class="h-64 sm:min-h-96">No exercises were found</div>
        </template>
      </ew-catalog-processor>
    </feed-view>
  </div>
</template>
